/* ===================================================================
 *
 *  Philosophy v1.0 Main Stylesheet
 *  1-2-2018
 *  ------------------------------------------------------------------
 *
 *  TOC:
 *  # base style overrides
 *    ## links
 *  # typography & general theme styles
 *    ## Lists
 *    ## responsive video container
 *    ## floated image
 *    ## tables
 *    ## spacing
 *    ## pace.js styles - minimal
 *  # preloader (line scale)
 *  # forms
 *    ## Style Placeholder Text
 *    ## Change Autocomplete styles in Chrome
 *  # buttons
 *  # additional components
 *    ## alert box
 *    ## additional typo styles
 *    ## pagination
 *  # reusable and common theme styles
 *    ## mediaelement style overrides
 *    ## close button for search and mobile navigation
 *    ## slick slider
 *    ## masonry entries
 *    ## post formats - masonry view
 *  # pageheader
 *    ## header logo
 *    ## header social
 *    ## header-toggle
 *    ## header-search
 *    ## header search trigger
 *    ## main navigation
 *    ## pageheader content
 *    ## feature section animation
 *  # s-content
 *    ## masonry layout
 *  # s-extra
 *    ## popular posts
 *    ## about
 *    ## tags
 *  # footer
 *    ## footer link list
 *    ## subscribe form
 *    ## copyright
 *    ## go to top
 *  # blog styles
 *    ## content main
 *    ## content media
 *    ## post tags
 *    ## author profile
 *    ## blog single page navigation
 *    ## format gallery
 *    ## format video
 *    ## format audio
 *    ## comments
 *  # page styles
 *    ## contact page
 *
 * =================================================================== */


/* ===================================================================
 * # base style overrides
 *
 * ------------------------------------------------------------------- */
html {
   font-size: 10px;
}

@media only screen and (max-width:400px) {
   html {
      font-size: 9.375px;
   }

}

html, body {
   height: 100%;
}

body {
   background: #19191b;
   font-family: "metropolis-regular", sans-serif;
   font-size: 1.6rem;
   font-style: normal;
   font-weight: normal;
   line-height: 1.875;
   color: #656565;
   margin: 0;
   padding: 0;
}


/* -------------------------------------------------------------------
 * ## links
 * ------------------------------------------------------------------- */
a {
   color: #0054a5;
   -webkit-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
}

a:hover, a:focus, a:active {
   color: #000000;
}

a:hover, a:active {
   outline: 0;
}



/* ===================================================================
 * # typography & general theme styles
 *
 * ------------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
   font-family: "librebaskerville-bold", serif;
   color: #000000;
   font-style: normal;
   font-weight: normal;
   text-rendering: optimizeLegibility;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
   margin-top: 6rem;
   margin-bottom: 1.8rem;
}

@media only screen and (max-width:600px) {
   h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
      margin-top: 5.1rem;
   }

}

h5, .h5, h6, .h6 {
   margin-top: 4.2rem;
   margin-bottom: 1.5rem;
}

@media only screen and (max-width:600px) {
   h5, .h5, h6, .h6 {
      margin-top: 3.6rem;
      margin-bottom: 0.9rem;
   }

}

h1, .h1 {
   font-size: 3.6rem;
   line-height: 1.25;
   letter-spacing: -.1rem;
}

@media only screen and (max-width:600px) {
   h1, .h1 {
      font-size: 3.3rem;
      letter-spacing: -.07rem;
   }

}

h2, .h2 {
   font-size: 3rem;
   line-height: 1.3;
}

@media only screen and (max-width:600px) {
   h2, .h2 {
      font-size: 2.8rem;
   }

}

h3, .h3 {
   font-size: 2.4rem;
   line-height: 1.25;
}

h4, .h4 {
   font-size: 2.1rem;
   line-height: 1.286;
}

h5, .h5 {
   font-size: 1.6rem;
   line-height: 1.313;
}

h6, .h6 {
   font-size: 1.3rem;
   line-height: 1.385;
   text-transform: uppercase;
   letter-spacing: .16rem;
}

p img {
   margin: 0;
}

p.lead {
   font-family: "metropolis-medium", sans-serif;
   font-size: 2rem;
   line-height: 1.8;
   margin-bottom: 3.6rem;
   color: #000000;
}

@media only screen and (max-width:800px) {
   p.lead {
      font-size: 1.8rem;
   }

}

em, i, strong, b {
   font-size: inherit;
   line-height: inherit;
   font-style: normal;
   font-weight: normal;
}

em, i {
   font-family: "metropolis-italic", sans-serif;
}

strong, b {
   font-family: "metropolis-bold", sans-serif;
}

small {
   font-size: 1.2rem;
   line-height: inherit;
}

blockquote {
   margin: 3.9rem 0;
   padding-left: 4.5rem;
   position: relative;
}

blockquote:before {
   content: "\201C";
   font-size: 10rem;
   line-height: 0px;
   margin: 0;
   color: rgba(0, 0, 0, 0.25);
   font-family: arial, sans-serif;
   position: absolute;
   top: 3.6rem;
   left: 0;
}

blockquote p {
   font-family: "metropolis-medium", sans-serif;
   padding: 0;
   font-size: 2.1rem;
   line-height: 1.857;
   color: #000000;
}

blockquote cite {
   display: block;
   font-family: "metropolis-medium", sans-serif;
   font-size: 1.5rem;
   font-style: normal;
   line-height: 1.4;
}

blockquote cite:before {
   content: "\2014 \0020";
}

blockquote cite a, blockquote cite a:visited {
   color: #727272;
   border: none;
}

abbr {
   font-family: "metropolis-bold", sans-serif;
   font-variant: small-caps;
   text-transform: lowercase;
   letter-spacing: .05rem;
   color: #727272;
}

var, kbd, samp, code, pre {
   font-family: Consolas, "Andale Mono", Courier, "Courier New", monospace;
}

pre {
   padding: 2.4rem 3rem 3rem;
   background: #F1F1F1;
   overflow-x: auto;
}

code {
   font-size: 1.4rem;
   margin: 0 .2rem;
   padding: .3rem .6rem;
   white-space: nowrap;
   background: #e5e5e5;
   border: 1px solid #d8d8d8;
   border-radius: 3px;
}

pre > code {
   display: block;
   white-space: pre;
   line-height: 2;
   padding: 0;
   margin: 0;
}

pre.prettyprint > code {
   border: none;
}

del {
   text-decoration: line-through;
}

abbr[title], dfn[title] {
   border-bottom: 1px dotted;
   cursor: help;
   text-decoration: none;
}

mark {
   background: #ffd900;
   color: #000000;
}

hr {
   border: solid rgba(0, 0, 0, 0.1);
   border-width: 1px 0 0;
   clear: both;
   margin: 2.4rem 0 1.5rem;
   height: 0;
}


/* -------------------------------------------------------------------
 * ## Lists
 * ------------------------------------------------------------------- */
ol {
   list-style: decimal;
}

ul {
   list-style: disc;
}

li {
   display: list-item;
}

ol, ul {
   margin-left: 1.7rem;
}

ul li {
   padding-left: .4rem;
}

ul ul, ul ol, ol ol, ol ul {
   margin: .6rem 0 .6rem 1.7rem;
}

ul.disc li {
   display: list-item;
   list-style: none;
   padding: 0 0 0 .8rem;
   position: relative;
}

ul.disc li::before {
   content: "";
   display: inline-block;
   width: 8px;
   height: 8px;
   border-radius: 50%;
   background: #0054a5;
   position: absolute;
   left: -17px;
   top: 11px;
   vertical-align: middle;
}

dt {
   margin: 0;
   color: #0054a5;
}

dd {
   margin: 0 0 0 2rem;
}


/* -------------------------------------------------------------------
 * ## responsive video container
 * ------------------------------------------------------------------- */
.video-container {
   position: relative;
   padding-bottom: 56.25%;
   height: 0;
   overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed,
.video-container video {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}


/* -------------------------------------------------------------------
 * ## floated image
 * ------------------------------------------------------------------- */
img.pull-right {
   margin: 1.5rem 0 0 3rem;
}

img.pull-left {
   margin: 1.5rem 3rem 0 0;
}


/* -------------------------------------------------------------------
 * ## tables
 * ------------------------------------------------------------------- */
table {
   border-width: 0;
   width: 100%;
   max-width: 100%;
   font-family: "metropolis-regular", sans-serif;
}

th, td {
   padding: 1.5rem 3rem;
   text-align: left;
   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

th {
   color: #000000;
   font-family: "metropolis-bold", sans-serif;
}

td {
   line-height: 1.5;
}

th:first-child, td:first-child {
   padding-left: 0;
}

th:last-child, td:last-child {
   padding-right: 0;
}

.table-responsive {
   overflow-x: auto;
   -webkit-overflow-scrolling: touch;
}


/* -------------------------------------------------------------------
 * ## spacing
 * ------------------------------------------------------------------- */
/*.btn {*/
   /*margin-bottom: 1.2rem;*/
/*}*/

/*fieldset {*/
   /*margin-bottom: 1.5rem;*/
/*}*/

/*input,*/
/*textarea,*/
/*select,*/
/*pre,*/
/*blockquote,*/
/*figure,*/
/*table,*/
/*p,*/
/*ul,*/
/*ol,*/
/*dl,*/
/*form,*/
/*.video-container,*/
/*.cl-custom-select {*/
   /*margin-bottom: 3rem;*/
/*}*/


/* -------------------------------------------------------------------
 * ## pace.js styles - minimal
 * ------------------------------------------------------------------- */
.pace {
   -webkit-pointer-events: none;
   pointer-events: none;
   -webkit-user-select: none;
   -moz-user-select: none;
   user-select: none;
}

.pace-inactive {
   display: none;
}

.pace .pace-progress {
   background: #FFFFFF;
   position: fixed;
   z-index: 900;
   top: 0;
   right: 100%;
   width: 100%;
   height: 4px;
}

.oldie .pace {
   display: none;
}



/* ===================================================================
 * # preloader (line scale)
 *
 * ------------------------------------------------------------------- */
#preloader {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: #050505;
   z-index: 800;
   height: 100%;
   width: 100%;
   display: table;
}

.no-js #preloader, .oldie #preloader {
   display: none;
}

#loader {
   display: table-cell;
   text-align: center;
   vertical-align: middle;
}

.line-scale > div:nth-child(1) {
   -webkit-animation: line-scale 1s -0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
   animation: line-scale 1s -0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.line-scale > div:nth-child(2) {
   -webkit-animation: line-scale 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
   animation: line-scale 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.line-scale > div:nth-child(3) {
   -webkit-animation: line-scale 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
   animation: line-scale 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.line-scale > div:nth-child(4) {
   -webkit-animation: line-scale 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
   animation: line-scale 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.line-scale > div:nth-child(5) {
   -webkit-animation: line-scale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
   animation: line-scale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.line-scale > div {
   background-color: #fff;
   width: 4px;
   height: 35px;
   border-radius: 2px;
   margin: 2px;
   -webkit-animation-fill-mode: both;
   animation-fill-mode: both;
   display: inline-block;
}

@-webkit-keyframes line-scale {
   0% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
   }

   50% {
      -webkit-transform: scaley(0.4);
      transform: scaley(0.4);
   }

   100% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
   }

}

@keyframes line-scale {
   0% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
   }

   50% {
      -webkit-transform: scaley(0.4);
      transform: scaley(0.4);
   }

   100% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
   }

}



/* ===================================================================
 * # forms
 *
 * ------------------------------------------------------------------- */
fieldset {
   border: none;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
   display: block;
   height: 6rem;
   padding: 1.5rem 0;
   border: 0;
   outline: none;
   color: #151515;
   font-family: "metropolis-regular", sans-serif;
   font-size: 1.6rem;
   line-height: 3rem;
   max-width: 100%;
   background: transparent;
   border-bottom: 2px solid rgba(0, 0, 0, 0.5);
   -webkit-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
}

.cl-custom-select {
   position: relative;
   padding: 0;
}

.cl-custom-select select {
   -webkit-appearance: none;
   -moz-appearance: none;
   -ms-appearance: none;
   -o-appearance: none;
   appearance: none;
   text-indent: 0.01px;
   text-overflow: '';
   margin: 0;
   line-height: 3rem;
   vertical-align: middle;
}

.cl-custom-select select option {
   padding-left: 2rem;
   padding-right: 2rem;
}

.cl-custom-select select::-ms-expand {
   display: none;
}

.cl-custom-select::after {
   border-bottom: 2px solid rgba(0, 0, 0, 0.5);
   border-right: 2px solid rgba(0, 0, 0, 0.5);
   content: '';
   display: block;
   height: 8px;
   width: 8px;
   margin-top: -7px;
   pointer-events: none;
   position: absolute;
   right: 2.4rem;
   top: 50%;
   -webkit-transform-origin: 66% 66%;
   -ms-transform-origin: 66% 66%;
   transform-origin: 66% 66%;
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
   -webkit-transition: all 0.15s ease-in-out;
   transition: all 0.15s ease-in-out;
}

/* IE9 and below */
.oldie .cl-custom-select::after {
   display: none;
}

textarea {
   min-height: 25rem;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
   color: #0054a5;
   border-bottom: 2px solid #0054a5;
}

label, legend {
   font-family: "metropolis-regular", sans-serif;
   font-size: 1.4rem;
   font-weight: bold;
   margin-bottom: .9rem;
   line-height: 1.714;
   color: #000000;
   display: block;
}

input[type="checkbox"], input[type="radio"] {
   display: inline;
}

label > .label-text {
   display: inline-block;
   margin-left: 1rem;
   font-family: "librebaskerville-regular", serif;
   font-weight: normal;
   line-height: inherit;
}

label > input[type="checkbox"], label > input[type="radio"] {
   margin: 0;
   position: relative;
   top: .15rem;
}


/* -------------------------------------------------------------------
 * ## Style Placeholder Text
 * ------------------------------------------------------------------- */
::-webkit-input-placeholder {
   color: #6d6d6d;
}

:-moz-placeholder {
   color: #6d6d6d;  /* Firefox 18- */
}

::-moz-placeholder {
   color: #6d6d6d;  /* Firefox 19+ */
}

:-ms-input-placeholder {
   color: #6d6d6d;
}

.placeholder {
   color: #6d6d6d !important;
}


/* -------------------------------------------------------------------
 * ## Change Autocomplete styles in Chrome
 * ------------------------------------------------------------------- */
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus
input:-webkit-autofill, textarea:-webkit-autofill, textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
   -webkit-text-fill-color: #0054a5;
   transition: background-color 5000s ease-in-out 0s;
}



/* ===================================================================
 * # buttons
 *
 * ------------------------------------------------------------------- */
/*.btn,*/
/*!*button,*!*/
input[type="submit"]
/*input[type="reset"],*/
/*input[type="button"]*/
{
   display: inline-block;
   font-family: "metropolis-bold", sans-serif;
   font-size: 1.2rem;
   text-transform: uppercase;
   letter-spacing: .3rem;
   height: 5.4rem;
   line-height: 5rem;
   padding: 0 3rem;
   margin: 0 .3rem 1.2rem 0;
   color: #000000;
   text-decoration: none;
   text-align: center;
   white-space: nowrap;
   border-radius: 3px;
   cursor: pointer;
   -webkit-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
   background-color: #c5c5c5;
   border: .2rem solid #c5c5c5;
}

/*.btn:hover,*/
/*!*button:hover,*!*/
/*input[type="submit"]:hover,*/
/*input[type="reset"]:hover,*/
/*input[type="button"]:hover,*/
/*.btn:focus,*/
/*!*button:focus,*!*/
/*input[type="submit"]:focus,*/
/*input[type="reset"]:focus,*/
/*input[type="button"]:focus {*/
   /*background-color: #b8b8b8;*/
   /*border-color: #b8b8b8;*/
   /*color: #000000;*/
   /*outline: 0;*/
/*}*/

/*!* button primary*/
 /** ------------------------------------------------- *!*/
/*.btn.btn--primary,*/
/*button.btn--primary,*/
/*input[type="submit"].btn--primary,*/
/*input[type="reset"].btn--primary,*/
/*input[type="button"].btn--primary {*/
   /*background: #000000;*/
   /*border-color: #000000;*/
   /*color: #FFFFFF;*/
/*}*/

/*.btn.btn--primary:hover,*/
/*button.btn--primary:hover,*/
/*input[type="submit"].btn--primary:hover,*/
/*input[type="reset"].btn--primary:hover,*/
/*input[type="button"].btn--primary:hover,*/
/*.btn.btn--primary:focus,*/
/*button.btn--primary:focus,*/
/*input[type="submit"].btn--primary:focus,*/
/*input[type="reset"].btn--primary:focus,*/
/*input[type="button"].btn--primary:focus {*/
   /*background: #0054a5;*/
   /*border-color: #0054a5;*/
/*}*/

/*!* button modifiers*/
 /** ------------------------------------------------- *!*/
/*.btn.full-width, button.full-width {*/
   /*width: 100%;*/
   /*margin-right: 0;*/
/*}*/

/*.btn--medium, button.btn--medium {*/
   /*height: 5.7rem !important;*/
   /*line-height: 5.3rem !important;*/
/*}*/

/*.btn--large, button.btn--large {*/
   /*height: 6rem !important;*/
   /*line-height: 5.6rem !important;*/
/*}*/

/*.btn--stroke, button.btn--stroke {*/
   /*background: transparent !important;*/
   /*border: 0.2rem solid #000000;*/
   /*color: #000000;*/
/*}*/

/*.btn--stroke:hover, button.btn--stroke:hover {*/
   /*border: 0.2rem solid #0054a5;*/
   /*color: #0054a5;*/
/*}*/

/*.btn--pill, button.btn--pill {*/
   /*padding-left: 3rem !important;*/
   /*padding-right: 3rem !important;*/
   /*border-radius: 1000px !important;*/
/*}*/

/*button::-moz-focus-inner, input::-moz-focus-inner {*/
   /*border: 0;*/
   /*padding: 0;*/
/*}*/



/* ===================================================================
 * # additional components
 *
 * ------------------------------------------------------------------- */

/* -------------------------------------------------------------------
 * ## alert box
 * ------------------------------------------------------------------- */
.alert-box {
   padding: 2.1rem 4rem 2.1rem 3rem;
   position: relative;
   margin-bottom: 3rem;
   border-radius: 3px;
   font-family: "metropolis-semibold", sans-serif;
   font-size: 1.5rem;
   line-height: 1.6;
}

.alert-box__close {
   position: absolute;
   right: 1.8rem;
   top: 1.8rem;
   cursor: pointer;
}

.alert-box__close.fa {
   font-size: 12px;
}

.alert-box--error {
   background-color: #ffd1d2;
   color: #e65153;
}

.alert-box--success {
   background-color: #c8e675;
   color: #758c36;
}

.alert-box--info {
   background-color: #d7ecfb;
   color: #4a95cc;
}

.alert-box--notice {
   background-color: #fff099;
   color: #bba31b;
}


/* -------------------------------------------------------------------
 * ## additional typo styles
 * ------------------------------------------------------------------- */

/* drop cap
 * ----------------------------------------------- */
.drop-cap::first-letter {
   float: left;
   margin: 0;
   padding: 1.5rem .6rem 0 0;
   font-size: 8.4rem;
   font-family: "metropolis-extrabold", sans-serif;
   font-weight: bold;
   line-height: 6rem;
   text-indent: 0;
   background: transparent;
   color: #000000;
}

/* line definition style
 * ----------------------------------------------- */
.lining dt, .lining dd {
   display: inline;
   margin: 0;
}

.lining dt + dt::before, .lining dd + dt::before {
   content: "\A";
   white-space: pre;
}

.lining dd + dd::before {
   content: ", ";
}

.lining dd + dd::before {
   content: ", ";
}

.lining dd::before {
   content: ": ";
   margin-left: -0.2em;
}

/* dictionary definition style
 * ----------------------------------------------- */
.dictionary-style dt {
   display: inline;
   counter-reset: definitions;
}

.dictionary-style dt + dt::before {
   content: ", ";
   margin-left: -0.2em;
}

.dictionary-style dd {
   display: block;
   counter-increment: definitions;
}

.dictionary-style dd::before {
   content: counter(definitions, decimal) ". ";
}

/**
 * Pull Quotes
 * -----------
 * markup:
 *
 * <aside class="pull-quote">
 *    <blockquote>
 *       <p></p>
 *    </blockquote>
 * </aside>
 *
 * --------------------------------------------------------------------- */
.pull-quote {
   position: relative;
   padding: 2.1rem 3rem 2.1rem 0px;
}

.pull-quote::before, .pull-quote::after {
   height: 1em;
   position: absolute;
   font-size: 10rem;
   font-family: Arial, Sans-Serif;
   color: rgba(0, 0, 0, 0.25);
}

.pull-quote::before {
   content: "\201C";
   top: -3.6rem;
   left: 0;
}

.pull-quote::after {
   content: '\201D';
   bottom: 3.6rem;
   right: 0;
}

.pull-quote blockquote {
   margin: 0;
}

.pull-quote blockquote::before {
   content: none;
}

/**
 * Stats Tab
 * ---------
 * markup:
 *
 * <ul class="stats-tabs">
 *    <li><a href="#">[value]<em>[name]</em></a></li>
 * </ul>
 *
 * Extend this object into your markup.
 *
 * --------------------------------------------------------------------- */
.stats-tabs {
   padding: 0;
   margin: 3rem 0;
}

.stats-tabs li {
   display: inline-block;
   margin: 0 1.5rem 3rem 0;
   padding: 0 1.5rem 0 0;
   border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.stats-tabs li:last-child {
   margin: 0;
   padding: 0;
   border: none;
}

.stats-tabs li a {
   display: inline-block;
   font-size: 2.5rem;
   font-family: "metropolis-bold", sans-serif;
   font-weight: bold;
   border: none;
   color: #000000;
}

.stats-tabs li a:hover {
   color: #0054a5;
}

.stats-tabs li a em {
   display: block;
   margin: .6rem 0 0 0;
   font-size: 1.5rem;
   font-family: "metropolis-regular", sans-serif;
   color: #727272;
}


/* -------------------------------------------------------------------
 * ## pagination
 * ------------------------------------------------------------------- */
.pgn {
   margin: 3rem auto;
   text-align: center;
}

.pgn ul {
   display: inline-block;
   list-style: none;
   margin-left: 0;
   position: relative;
   padding: 0 6rem;
}

.pgn ul li {
   display: inline-block;
   margin: 0;
   padding: 0;
}

.pgn__num {
   font-family: "metropolis-bold", sans-serif;
   font-size: 1.5rem;
   line-height: 2.4rem;
   display: inline-block;
   padding: .6rem 1.2rem;
   height: 3.6rem;
   margin: .3rem .15rem;
   color: #151515;
   border-radius: 3px;
   -webkit-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
}

.pgn__num:hover {
   background: #c4c4c4;
   color: #151515;
}

.pgn .current, .pgn .current:hover {
   background-color: #151515;
   color: #FFFFFF;
}

.pgn .inactive, .pgn .inactive:hover {
   color: #888888;
   cursor: default;
}

.pgn__prev, .pgn__next {
   display: block;
   background-color: #FFFFFF;
   background-repeat: no-repeat;
   background-size: 18px 12px;
   background-position: center;
   height: 4.8rem;
   width: 4.8rem;
   line-height: 4.8rem;
   padding: 0;
   margin: 0;
   border-radius: 50%;
   box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
   font: 0/0 a;
   text-shadow: none;
   color: transparent;
   -webkit-transition: all 0.2s ease-in-out;
   transition: all 0.2s ease-in-out;
   position: absolute;
   top: 50%;
   -webkit-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
   transform: translateY(-50%);
}

.pgn__prev:hover, .pgn__next:hover {
   background-color: #151515;
   color: #FFFFFF;
   text-decoration: none;
   background-repeat: no-repeat;
   background-position: center;
}

.pgn__prev {
   background-image: url("../images/icons/icon-arrow-left.svg");
   left: 0;
}

.pgn__prev:hover {
   background-image: url("../images/icons/icon-arrow-left-w.svg");
}

.pgn__next {
   background-image: url("../images/icons/icon-arrow-right.svg");
   right: 0;
}

.pgn__next:hover {
   background-image: url("../images/icons/icon-arrow-right-w.svg");
}

.pgn__prev.inactive, .pgn__next.inactive {
   background-color: #ffffff;
   opacity: 0.5;
   cursor: default;
}

.pgn__prev.inactive:hover {
   background-image: url("../images/icons/icon-arrow-left.svg");
}

.pgn__next.inactive:hover {
   background-image: url("../images/icons/icon-arrow-right.svg");
}

/* -------------------------------------------------------------------
 * responsive:
 * pagination
 * ------------------------------------------------------------------- */
@media only screen and (max-width:600px) {
   .pgn ul {
      padding: 0 5rem;
   }

   .pgn__prev, .pgn__next {
      height: 3.6rem;
      width: 3.6rem;
      line-height: 3.6rem;
      background-size: 12px 8px;
   }

}



/* ===================================================================
 * # reusable and common theme styles
 *
 * ------------------------------------------------------------------- */
.wide {
   max-width: 1400px;
}

.narrow {
   max-width: 800px;
}


/* -------------------------------------------------------------------
 * ## mediaelement style overrides
 * ------------------------------------------------------------------- */
.mejs__overlay-button {
   background-image: url("../images/mejs/mejs-controls.svg");
}

.mejs__overlay-loading-bg-img {
   background-image: url("../images/mejs/mejs-controls.svg");
}

.mejs__button > button {
   background-image: url("../images/mejs/mejs-controls.svg");
   background-color: transparent;
   outline: none !important;
}

.audio-wrap .mejs__container {
   background-color: rgba(0, 0, 0, 0.8) !important;
   font-family: "metropolis-light", sans-serif;
}

.audio-wrap .mejs__time-buffering,
.audio-wrap .mejs__time-current,
.audio-wrap .mejs__time-handle,
.audio-wrap .mejs__time-loaded,
.audio-wrap .mejs__time-marker,
.audio-wrap .mejs__time-total,
.audio-wrap .mejs__time-hovered {
   height: 2px;
}

.audio-wrap .mejs__time-total {
   margin-top: 9px;
}

.audio-wrap .mejs__time-handle {
   left: -5px;
   width: 12px;
   height: 12px;
   border-radius: 50%;
   background: #FFFFFF;
   top: -5px;
   cursor: pointer;
   display: block;
   position: absolute;
   z-index: 2;
   border: none;
}

.audio-wrap .mejs__time-handle-content {
   top: 0;
   left: 0;
   width: 12px;
   height: 12px;
}

.audio-wrap .mejs__horizontal-volume-total,
.audio-wrap .mejs__horizontal-volume-current {
   border-radius: 0;
}


/* -------------------------------------------------------------------
 * ## close button for search and mobile navigation
 * ------------------------------------------------------------------- */
.header__overlay-close {
   position: absolute;
   display: block;
   width: 45px;
   height: 45px;
   top: 21px;
   left: 50%;
   margin-left: -23px;
   font: 0/0 a;
   text-shadow: none;
   color: transparent;
}

.header__overlay-close::before, .header__overlay-close::after {
   content: '';
   position: absolute;
   display: inline-block;
   width: 2px;
   height: 20px;
   top: 12px;
   left: 22px;
   background-color: #FFFFFF;
}

.header__overlay-close::before {
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
}

.header__overlay-close::after {
   -webkit-transform: rotate(-45deg);
   -ms-transform: rotate(-45deg);
   transform: rotate(-45deg);
}


/* -------------------------------------------------------------------
 * ## slick slider
 * ------------------------------------------------------------------- */
.slick-slider .slick-slide {
   outline: none;
}

.slick-slider .slick-dots {
   display: block;
   list-style: none;
   width: 100%;
   padding: 0;
   margin: 3rem 0 0 0;
   text-align: center;
   position: absolute;
   top: 100%;
   left: 0;
}

.slick-slider .slick-dots li {
   display: inline-block;
   width: 18px;
   height: 18px;
   margin: 0;
   padding: 4px;
   cursor: pointer;
}

.slick-slider .slick-dots li button {
   display: block;
   width: 10px;
   height: 10px;
   border-radius: 50%;
   background: rgba(255, 255, 255, 0.6);
   border: none;
   line-height: 10px;
   padding: 0;
   margin: 0;
   cursor: pointer;
   font: 0/0 a;
   text-shadow: none;
   color: transparent;
}

.slick-slider .slick-dots li button:hover,
.slick-slider .slick-dots li button:focus {
   outline: none;
}

.slick-slider .slick-dots li.slick-active button,
.slick-slider .slick-dots li:hover button {
   background: #FFFFFF;
}


/* -------------------------------------------------------------------
 * ## masonry entries
 * ------------------------------------------------------------------- */
.masonry-entry {
   margin-bottom: 40px;
}

.entry__thumb, .entry__text {
   box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
}

.entry__thumb {
   position: relative;
   overflow: hidden;
}

.entry__thumb-link img {
   vertical-align: bottom;
   -webkit-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
}

.entry__thumb-link::before {
   content: "";
   display: block;
   background: rgba(0, 0, 0, 0.8);
   opacity: 0;
   visibility: hidden;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   -webkit-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
   z-index: 1;
}

.entry__thumb-link::after {
   content: "...";
   font-family: georgia, serif;
   font-size: 2.7rem;
   z-index: 1;
   display: block;
   height: 90px;
   width: 90px;
   letter-spacing: -1px;
   line-height: 90px;
   margin-left: -45px;
   margin-top: -45px;
   position: absolute;
   left: 50%;
   top: 50%;
   text-align: center;
   color: #FFFFFF;
   opacity: 0;
   visibility: hidden;
   -webkit-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
   -webkit-transform: scale(0.5);
   -ms-transform: scale(0.5);
   transform: scale(0.5);
}

.entry__thumb:hover .entry__thumb-link::before {
   opacity: 1;
   visibility: visible;
}

.entry__thumb:hover .entry__thumb-link::after {
   opacity: 1;
   visibility: visible;
   -webkit-transform: scale(1);
   -ms-transform: scale(1);
   transform: scale(1);
}

.entry__thumb:hover .entry__thumb-link img {
   -webkit-transform: scale(1.05);
   -ms-transform: scale(1.05);
   transform: scale(1.05);
}

.entry__text {
   padding: 3rem 2.8rem 3.6rem;
   background-color: #FFFFFF;
}

.entry__title {
   font-size: 2.4rem;
   line-height: 1.375;
   margin-top: 0;
   margin-bottom: 2.1rem;
}

.entry__title a {
   color: #000000;
   border-bottom: 1px solid transparent;
}

.entry__title a:hover, .entry__title a:focus {
   border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.entry__date {
   font-family: "metropolis-medium", sans-serif;
   font-size: 1.4rem;
   line-height: 1.714;
   margin-bottom: .6rem;
}

.entry__date a {
   color: #858585;
}

.entry__excerpt {
   font-size: 1.5rem;
   line-height: 1.8;
}

.entry__meta {
   font-family: "metropolis-semibold", sans-serif;
   font-size: 1.5rem;
   margin-bottom: 0;
  margin-top: 20px;
}

.entry__meta a {
   color: #0054a5;
}

.entry__meta a:hover, .entry__meta a:focus {
   color: #000000;
}

.entry__meta-links a::after {
   content: ", ";
}

.entry__meta-links a:last-child::after {
   display: none;
}


/* -------------------------------------------------------------------
 *  ## post formats - masonry view
 * ------------------------------------------------------------------- */

/* format quote & format link
 * --------------------------------------- */
.format-quote, .format-link {
   text-align: center;
}

.format-quote .entry__thumb,
.format-link .entry__thumb {
   display: table;
   background: #FFFFFF;
   padding: 2.4rem 3rem 4.8rem;
   min-height: 324px;
   width: 100%;
   position: relative;
}

.format-quote .entry__thumb::before,
.format-link .entry__thumb::before {
   content: "";
   display: block;
   height: 3.6rem;
   width: 3.6rem;
   background-position: center center;
   background-repeat: no-repeat;
   background-size: contain;
   opacity: .2;
   margin-bottom: 1.5rem;
   position: absolute;
   top: 3.3rem;
   left: 50%;
   -webkit-transform: translateX(-50%);
   -ms-transform: translateX(-50%);
   transform: translateX(-50%);
}

.format-quote blockquote::before {
   display: none;
}

.format-quote blockquote, .format-link .link-wrap {
   display: table-cell;
   margin: 0;
   padding-left: 0;
   vertical-align: middle;
}

.format-quote blockquote p, .format-link .link-wrap p {
   font-family: "librebaskerville-bold", serif;
   color: #000000;
   padding: 7.8rem 0 0 0;
   margin-bottom: 3.3rem;
   font-size: 2.4rem;
   line-height: 1.625;
}

.format-quote cite, .format-link cite {
   display: block;
   font-family: "metropolis-regular", sans-serif;
   font-size: 1.5rem;
   font-style: normal;
   line-height: 1.6;
   color: #999999;
}

.format-quote cite::before,
.format-link cite::before {
   display: none;
}

.format-quote cite a,
.format-quote cite a:visited {
   color: #999999;
   border: none;
}

.format-quote .entry__thumb::before {
   background-image: url(../images/icons/icon-quote.svg);
}

.format-link .entry__thumb::before {
   background-image: url(../images/icons/icon-link.svg);
   width: 3.3rem;
}

.format-link .link-wrap cite:before {
   display: none;
}

.format-link .link-wrap cite a,
.format-link .link-wrap cite a:visited {
   color: #999999;
   display: inline-block;
   padding-bottom: .3rem;
}

.format-link .link-wrap cite a:hover,
.format-link .link-wrap cite a:focus {
   color: #000000;
   border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

/* format video
 * --------------------------------------- */
.format-video .entry__thumb a {
   cursor: zoom-in;
   display: block;
   position: relative;
}

.format-video .entry__thumb a img {
   vertical-align: bottom;
}

.format-video .entry__thumb a::before {
   content: "";
   display: block;
   height: 4.5rem;
   width: 4.5rem;
   position: absolute;
   bottom: 3rem;
   left: 3rem;
   cursor: zoom-in;
   background-image: url(../images/icons/icon-video.svg);
   background-position: center center;
   background-repeat: no-repeat;
   background-size: contain;
}



/* ===================================================================
 * # pageheader
 *
 * ------------------------------------------------------------------- */
.s-pageheader {
   min-height: 174px;
   background-image: url(../images/hero-bg.jpg);
   background-repeat: no-repeat;
   background-position: center center;
   position: relative;
}

.s-pageheader::before {
   display: block;
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: #151515;
   opacity: .95;
}

.s-pageheader--home {
   min-height: 480px;
   padding-top: 222px;
}

.header {
   position: absolute;
   text-align: center;
   top: 30px;
   width: 100%;
}

.header__content {
   position: relative;
   padding: 0 20px;
}

.pageheader-content {
   overflow: hidden;
   padding: 0 75px;
   max-width: 1350px;
}


/* -------------------------------------------------------------------
 * ## header logo
 * ------------------------------------------------------------------- */
.header__logo {
   display: inline-block;
   margin: 0;
   padding: 0;
   z-index: 501;
}

.header__logo a {
   display: block;
   margin: 0 0 0 6px;
   padding: 0;
   outline: 0;
   border: none;
   -webkit-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
}

.header__logo img {
   width: 265px;
   height: 48px;
}


/* -------------------------------------------------------------------
 * ## header social
 * ------------------------------------------------------------------- */
.header__social {
   list-style: none;
   font-size: 23px;
   line-height: 1;
   margin: 0;
   padding: 0;
   position: absolute;
   left: 20px;
   top: 12px;
}

.header__social a {
   color: #FFFFFF;
}

.header__social li {
   display: inline-block;
   margin-right: 5px;
   margin-left: 5px;
   padding-left: 0;
}

.header__social li:last-child {
   margin-right: 0;
}

.header__social li:first-child {
   margin-left: 0;
}


/* -------------------------------------------------------------------
 * ## header-toggle
 * ------------------------------------------------------------------- */
.header__toggle-menu {
   display: none;
   width: 40px;
   height: 40px;
   position: absolute;
   left: 20px;
   top: 6px;
}

.header__toggle-menu span {
   display: block;
   width: 22px;
   height: 2px;
   margin-top: -1px;
   position: absolute;
   right: 9px;
   top: 50%;
   bottom: auto;
   left: auto;
   background-color: white;
   -webkit-transition: background 0.2s ease-in-out;
   transition: background 0.2s ease-in-out;
   font: 0/0 a;
   text-shadow: none;
   color: transparent;
}

.header__toggle-menu span::before,
.header__toggle-menu span::after {
   content: '';
   width: 100%;
   height: 100%;
   position: absolute;
   background-color: inherit;
   left: 0;
}

.header__toggle-menu span::before {
   top: -8px;
}

.header__toggle-menu span::after {
   bottom: -8px;
}


/* -------------------------------------------------------------------
 * ## header-search
 * ------------------------------------------------------------------- */
.header__search {
   display: block;
   text-align: center;
   background: #151515;
   opacity: 0;
   visibility: hidden;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   -webkit-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
   z-index: 900;
}

.header__search-form {
   width: 100%;
   position: absolute;
   top: 50%;
   -webkit-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
   transform: translateY(-50%);
}

.header__search-form label {
   color: #FFFFFF;
}

.header__search-form::after {
   content: "Press Enter to begin your search.";
   display: block;
   letter-spacing: 0.6px;
   font-size: 1.6rem;
   margin-top: 3rem;
   text-align: center;
   color: rgba(255, 255, 255, 0.5);
}

.header__search-form input[type="search"] {
   background-color: transparent;
   color: #000000;
   height: auto;
   width: 100%;
   font-family: "metropolis-semibold", sans-serif;
   font-size: 6rem;
   line-height: 1.5;
   border: none;
   border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
   max-width: 680px;
   padding-top: .6rem !important;
   padding-bottom: .6rem !important;
   margin: 0 auto;
}

.header__search-form input[type="search"]::-webkit-input-placeholder {
   color: #FFFFFF;
   text-align: center !important;
   opacity: 1;
}

.header__search-form input[type="search"]:-moz-placeholder {
   color: #FFFFFF;
   text-align: center !important;
   opacity: 1;
}

.header__search-form input[type="search"]::-moz-placeholder {
   color: #FFFFFF;
   text-align: center !important;
   opacity: 1;
}

.header__search-form input[type="search"]:-ms-input-placeholder {
   color: #FFFFFF;
   text-align: center !important;
   opacity: 1;
}

.header__search-form input[type="search"].placeholder {
   color: #FFFFFF;
   text-align: center !important;
   opacity: 1;
}

.header__search-form input[type="search"]:focus {
   outline: none;
}

.header__search-form input[type="submit"] {
   display: none;
}

body.search-is-visible {
   overflow: hidden;
}

.search-is-visible .header__search {
   opacity: 1;
   visibility: visible;
}

/* -------------------------------------------------------------------
 * ## header search trigger
 * ------------------------------------------------------------------- */

#searchForm {
   height: 30px;
   min-width: 21px;
   position: absolute;
   right: 20px;
   top: 12px;
}

#searchForm.on {
   -webkit-animation-name: in-out;
   animation-name: in-out;
   -webkit-animation-duration: 0.7s;
   animation-duration: 0.7s;
   -webkit-animation-timing-function: linear;
   animation-timing-function: linear;
   -webkit-animation-iteration-count: 1;
   animation-iteration-count: 1;
}

.input {
   position: absolute;
   box-sizing: border-box;
   width: 20px;
   height: 20px;
   top: 12px;
   left: 0px;
   border: 2px solid #ffffff;
   border-radius: 50%;
   background: none;
   color: #fff;
   font-size: 16px;
   font-weight: 400;
   font-family: Roboto;
   outline: 0;
   -webkit-transition: width 0.4s ease-in-out, border-radius 0.8s ease-in-out,
   padding 0.2s;
   transition: width 0.4s ease-in-out, border-radius 0.8s ease-in-out,
   padding 0.2s;
   -webkit-transition-delay: 0.4s;
   transition-delay: 0.4s;
   -webkit-transform: translate(-100%, -50%);
   -ms-transform: translate(-100%, -50%);
   transform: translate(-100%, -50%);
}

.search {
   background: none;
   position: absolute;
   top: 12px;
   left: 0;
   height: 20px;
   width: 20px;
   padding: 0;
   padding-right: 5px;
   border-radius: 100%;
   outline: 0;
   border: 0;
   color: #FFFFFF;
   cursor: pointer;
   -webkit-transition: 0.2s ease-in-out;
   transition: 0.2s ease-in-out;
   -webkit-transform: translate(-100%, -50%);
   -ms-transform: translate(-100%, -50%);
   transform: translate(-100%, -50%);
}

.search:before {
   content: "";
   position: absolute;
   width: 10px;
   height: 3px;
   background-color: #fff;
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
   margin-top: 12px;
   margin-left: 10px;
   -webkit-transition: 0.2s ease-in-out;
   transition: 0.2s ease-in-out;
}

.closedSearchBox {
   margin-left: 2px;
}

.closedSearchBox:before {
   content: "";
   position: absolute;
   width: 16px;
   height: 2px;
   margin-top: -1px;
   margin-left: -8px;
   background-color: #fff;
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
   -webkit-transition: 0.2s ease-in-out;
   transition: 0.2s ease-in-out;
}

.closedSearchBox:after {
   content: "";
   position: absolute;
   width: 16px;
   height: 2px;
   background-color: #fff;
   margin-top: -1px;
   margin-left: -8px;
   cursor: pointer;
   -webkit-transform: rotate(-45deg);
   -ms-transform: rotate(-45deg);
   transform: rotate(-45deg);
}

.openSearchBox {
   box-sizing: border-box;
   padding: 0 25px 0 5px;
   width: 200px;
   height: 20px;
   border: 2px solid #ffffff;
   border-radius: 0;
   background: none;
   color: #fff;
   font-family: Roboto;
   font-size: 18px;
   font-weight: 400;
   outline: 0;
   -webkit-transition: width 0.4s ease-in-out, border-radius 0.4s ease-in-out,
   padding 0.2s;
   transition: width 0.4s ease-in-out, border-radius 0.4s ease-in-out,
   padding 0.2s;
   -webkit-transition-delay: 0.4s, 0s, 0.4s;
   transition-delay: 0.4s, 0s, 0.4s;
   -webkit-transform: translate(-100%, -50%);
   -ms-transform: translate(-100%, -50%);
   transform: translate(-100%, -50%);
}

.searchIndex{
   font-family: "metropolis-semibold", sans-serif;
   color: #FFFFFF;
   display: block;
   float: left;
   font-size: 12px;
   text-transform: uppercase;
   letter-spacing: 2px;
   position: absolute;
   margin-left: -85px;
   top: 6px;
   padding-right: 0px;
}

.searchHidden {
   visibility: hidden ;
   opacity: 0;
   transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.searchVisible {
   visibility: visible;
   opacity: 1;
   transition: opacity 0.5s linear;
}

/* -------------------------------------------------------------------
 * responsive:
 * search field - less than 800px
 * ------------------------------------------------------------------- */

.form-mobile {
   width: 80%;
   border-radius: 10px;
   display: block;
   height: 50px;
   background-color: #FFFFFF;
   top: -45px;
   position: relative;
   padding: 0 20px;
   margin: 0 auto;
   box-shadow: 2px 2px 2px 1px #999999;
}

@media only screen and (max-width:600px) {
   .form-mobile {
      width: 60%;
      height: 30px;
   }
}

@media only screen and (max-width:400px) {
   .form-mobile {
      height: 25px;
      top: -30px;
      position: relative;
   }
   .mobile-search-input {
      font-weight: normal !important;
      font-size: 0.8em !important;
      margin-bottom: 3px !important;
      margin-top: 2px !important;
   }
}

.mobile-search-input {
   width: 100%;
   height: 80%;
   border: none;
   outline: none;
   margin-top: 5px;
   font-size: 1.1em;
   font-weight: bold;
}

.mobileSearchHidden {
   visibility: hidden ;
   opacity: 0;
   transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.mobileSearchVisible {
   visibility: visible;
   opacity: 1;
   transition: opacity 0.5s linear;
}

/* -------------------------------------------------------------------
 * responsive:
 * header components
 * ------------------------------------------------------------------- */
@media only screen and (max-width:1000px) {
   .header__social {
      font-size: 21px;
   }

}

@media only screen and (max-width:900px) {
   .header__logo img {
      width: 250px;
      height: 45px;
   }

}

@media only screen and (max-width:800px) {
   .s-pageheader {
      min-height: 90px;
   }

   .s-pageheader--home {
      padding-top: 120px;
   }

   .header {
      top: 24px;
   }

   .header__logo img {
      width: 225px;
      height: 40px;
   }

   .header__social {
      display: none;
   }

   .header__toggle-menu {
      top: 0;
      display: block;
   }

   #searchForm {
      top: 6px;
   }

   .searchVisible {
      display: none;
   }

   .header__search-form::after {
      font-size: 1.5rem;
   }

   .header__search-form input[type="search"] {
      max-width: none;
      width: 80%;
      font-size: 4.2rem;
   }

}

@media only screen and (max-width:600px) {
   .s-pageheader {
      min-height: 84px;
   }

   .header__logo img {
      width: 200px;
      height: 36px;
   }

   .header__toggle-menu {
      top: -3px;
   }

   #searchForm {
      top: 4px;
   }

   .header__search-form input[type="search"] {
      font-size: 3.4rem;
   }

}

@media only screen and (max-width:400px) {
   .s-pageheader {
      min-height: 78px;
   }

   .s-pageheader--home {
      padding-top: 96px;
   }

   .header__logo img {
      width: 160px;
      height: 29px;
   }

   .header__toggle-menu {
      top: -7px;
   }

   #searchForm {
      top: 1px;
   }

   .header__search-form input[type="search"] {
      font-size: 2.6rem;
   }

}


/* -------------------------------------------------------------------
 * ## main navigation
 * ------------------------------------------------------------------- */
.header__nav-wrap {
   margin-top: 15px;
   border-top: 1px solid rgba(255, 255, 255, 0.04);
   padding-top: 15px;
}

.header__nav-wrap a {
   color: rgba(255, 255, 255, 0.6);
}

.header__nav-wrap {
   text-align: center;
   font-family: "metropolis-regular", sans-serif;
   font-size: 1.4rem;
   color: #FFFFFF;
}

.header__nav-wrap ,
.header__nav-wrap .close-mobile-menu {
   display: block;
}

.header__nav {
   list-style: none;
   margin: 0;
   padding: 0;
   font-family: "metropolis-medium", sans-serif;
   font-size: 15px;
}

.header__nav li {
   display: inline-block;
   position: relative;
   padding: 0 2rem;
}

.header__nav li.has-children {
   padding-right: 3.3rem;
}

.header__nav li a {
   display: block;
   color: rgba(255, 255, 255, 0.6);
   line-height: 39px;
}

.header__nav li.has-children > a::after {
   border-bottom: 1px solid rgba(255, 255, 255, 0.3);
   border-right: 1px solid rgba(255, 255, 255, 0.3);
   content: '';
   display: block;
   height: 5px;
   width: 5px;
   margin-top: -4px;
   pointer-events: none;
   position: absolute;
   right: 1.8rem;
   top: 21px;
   -webkit-transform-origin: 66% 66%;
   -ms-transform-origin: 66% 66%;
   transform-origin: 66% 66%;
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
   -webkit-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
}

.header__nav li:hover > a, .header__nav li:focus > a {
   color: #FFFFFF;
}

.header__nav li:hover li, .header__nav li:focus li {
   background: transparent;
}

.header__nav li.current > a {
   color: #FFFFFF;
}

.header__nav li ul {
   position: absolute;
   top: 45px;
   left: 0;
   font-size: 14px;
   margin: 0;
   padding: 1.8rem 0;
   background: #050505;
   border-radius: 0 0 3px 3px;
   z-index: 500;
   -webkit-transform: translate3d(0, 15px, 0);
   -ms-transform: translate3d(0, 15px, 0);
   transform: translate3d(0, 15px, 0);
   -webkit-transition: all 0.5s ease-in-out;
   transition: all 0.5s ease-in-out;
   opacity: 0;
   visibility: hidden;
}

.header__nav li ul ul {
   position: absolute;
   top: 0;
   left: 100%;
   left: calc(100% + 1px);
   border-radius: 0 0 3px 3px;
   padding-top: 1.2rem;
}

.header__nav li ul li {
   display: block;
   text-align: left;
   padding: 0;
   margin: 0;
   min-height: 33px;
   width: 100%;
}

.header__nav li ul li a {
   display: block;
   white-space: nowrap;
   padding: 7.5px 3rem 7.5px 2rem;
   font-family: "metropolis-regular", sans-serif;
   line-height: 18px;
   color: rgba(255, 255, 255, 0.6);
}

.header__nav li ul li a:hover, .header__nav li ul li a:focus {
   color: #FFFFFF;
}

.header__nav li:hover > ul {
   opacity: 1;
   visibility: visible;
   -webkit-transform: translate3d(0, 0, 0);
   -ms-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
}

/* -------------------------------------------------------------------
 * burger modification
 * ------------------------------------------------------------------- */
@media only screen and (max-width: 800px) {
  .burgerShow {
    top: -36px;
  }
  .burger {
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 801px) {
  .burgerHidden {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .burgerShow {
    top: -30px;
  }
}

/* -------------------------------------------------------------------
 * responsive:
 * main navigation
 * ------------------------------------------------------------------- */
@media only screen and (min-width:801px) {
   .header__nav li.has-children:hover > a::after,
   .header__nav li.has-children:focus > a::after {
      -webkit-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
      transform: rotate(225deg);
   }

   .header__nav li ul {
      display: block !important;
   }

}

@media only screen and (max-width:800px) {
   .header__nav-wrap {
      background-color: #151515;
      opacity: 0;
      visibility: hidden;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      margin: 0;
      border: none;
      z-index: 600;
      overflow-y: auto;
   }

   .header__nav-wrap ,
   .header__nav-wrap .close-mobile-menu {
      display: block;
   }

   .header__nav {
      margin: 4.8rem 6rem 3rem 6rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.03);
      font-family: "metropolis-semibold", sans-serif;
      font-size: 16px;
   }

   .header__nav li {
      display: block;
      padding: 0;
      text-align: left;
   }

   .header__nav li ul {
      display: none;
      position: static;
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
      visibility: visible;
      background-color: transparent;
      padding: 0 0 1.8rem 0;
      -webkit-transition: none !important;
      transition: none !important;
   }

   .header__nav li.has-children > a::after {
      top: 27px;
   }

   .header__nav li.has-children > a.sub-menu-is-open::after {
      -webkit-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
      transform: rotate(225deg);
   }

   .header__nav li ul li a {
      padding: 7.5px 1.5rem 7.5px 1.5rem;
   }

   .header__nav > li {
      border-top: 1px solid rgba(255, 255, 255, 0.03);
   }

   .header__nav > li a {
      line-height: 54px;
   }

   body.nav-wrap-is-visible {
      overflow: hidden;
   }

   .nav-wrap-is-visible .header__nav-wrap {
      opacity: 1;
      visibility: visible;
   }

}

@media only screen and (max-width:400px) {
   .header__nav {
      margin: 4.2rem 4.2rem 3rem 4.2rem;
   }

}
/* -------------------------------------------------------------------
 * react-burger-menu
 * ------------------------------------------------------------------- */
/* Personal CSS for Side Menu */
.side-menu {
  font-family: "metropolis-semibold", sans-serif;
  font-size: 25px;
  margin-bottom: 30px;
  outline: none;
}

.side-menu a {
  color: rgba(255, 255, 255, 0.6);
}

.side-menu a:hover {
  color: #FFFFFF;
}

.current-item a {
  color: #FFFFFF;
}

/* Position and sizing of burger button */
.bm-burger-button {
  visibility: hidden;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  height: 10% !important;
  background: #FFFFFF;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #bdc3c7;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 5px;
  width: 5px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #1d3357;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 800px) {
  .bm-burger-button {
    position: fixed;
    width: 35px;
    height: 25px;
    left: 35px;
    top: 30px;
    visibility: visible;
  }
}

/* -------------------------------------------------------------------
 * ## pageheader content
 * ------------------------------------------------------------------- */
.featured {
   background-color: #151515;
   height: 564px;
   position: relative;
}

.featured::before, .featured::after {
   content: "";
   display: block;
   height: 1px;
   width: 60px;
   background-color: rgba(255, 255, 255, 0.15);
   position: absolute;
   top: 50%;
}

.featured::before {
   left: -75px;
}

.featured::after {
   right: -75px;
}

.featured__column {
   float: left;
}

.featured .entry {
   display: block;
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   overflow: hidden;
   position: relative;
}

.featured .entry h1 {
   font-size: 3.6rem;
   line-height: 1.333;
   margin-top: 0;
   margin-bottom: 3rem;
}

.featured .entry h1 a {
   color: #FFFFFF;
   border-bottom: 1px solid transparent;
   -webkit-transition: all 0.5s ease-in-out;
   transition: all 0.5s ease-in-out;
}

.featured .entry h1 a:focus, .featured .entry h1 a:hover {
   border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.featured .entry::before {
   display: block;
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   -webkit-transition: all 0.4s ease-in-out;
   transition: all 0.4s ease-in-out;
   opacity: .5;
   background-color: #151515;
}

.featured .entry:hover::before {
   opacity: .8;
}

.featured .entry__content {
   position: absolute;
   left: 0;
   bottom: 6rem;
   width: 100%;
   padding: 0 20% 0 6rem;
   display: block;
}

.featured .entry__category {
   display: inline-block;
   font-family: "metropolis-bold", sans-serif;
   font-size: 1.1rem;
   line-height: 2.4rem;
   text-transform: uppercase;
   letter-spacing: .2rem;
   margin-bottom: 2.4rem;
}

.featured .entry__category a {
   display: inline-block;
   color: #FFFFFF;
   padding: 0 1.5rem;
}

.featured .entry__info {
   position: relative;
}

.featured .entry__profile-pic {
   float: left;
   height: 4.2rem;
   width: 4.2rem;
   margin-top: -.9rem;
}

.featured .entry__profile-pic img {
   border-radius: 50%;
}

.featured .entry__meta {
   list-style: none;
   margin: 0 0 0 6rem;
   padding: 0;
   font-family: "metropolis-semibold", sans-serif;
   color: rgba(255, 255, 255, 0.5);
   line-height: 1.5;
}

.featured .entry__meta li {
   display: inline-block;
   padding-left: 0;
}

.featured .entry__meta li::after {
   content: '\2022';
   margin-left: 9px;
   margin-right: 6px;
}

.featured .entry__meta li:last-child::after {
   display: none;
}

.featured .entry__meta a {
   color: rgba(255, 255, 255, 0.5);
}

.featured .entry__meta a:focus, .featured .entry__meta a:hover {
   color: #FFFFFF;
}

.featured__column--big {
   width: 66.66666%;
   position: relative;
}

.featured__column--big .entry {
   height: 564px;
}

.featured__column--big .entry__category a {
   background-color: #01aef0;
}

.featured__column--small {
   width: 33.333333333%;
   position: relative;
}

.featured__column--small .entry {
   height: 282px;
}

.featured__column--small .entry h1 {
   font-size: 2rem;
   margin-bottom: 1.2rem;
}

.featured__column--small .entry__content {
   bottom: 2.4rem;
   padding: 0 10% 0 3rem;
}

.featured__column--small .entry__category {
   font-size: 1rem;
   line-height: 2.1rem;
   margin-bottom: 1.5rem;
}

.featured__column--small .entry__category a {
   background-color: #679a23;
}

.featured__column--small .entry__profile-pic {
   display: none;
}

.featured__column--small .entry__meta {
   margin: 0;
   font-size: 1.4rem;
}

/* animate .featured
 * ------------------------------------------------------------------- */
html.cl-preload .featured {
   opacity: 0;
}

html.cl-loaded .featured {
   animation-duration: 2s;
   -webkit-animation-name: fadeIn;
   animation-name: fadeIn;
}

html.no-csstransitions .featured {
   opacity: 1;
}


/* -------------------------------------------------------------------
 * ## feature section animation
 * ------------------------------------------------------------------- */

/* fade in */
@-webkit-keyframes fadeIn {
   from {
      opacity: 0;
      -webkit-transform: translate3d(0, 150%, 0);
      -ms-transform: translate3d(0, 150%, 0);
      transform: translate3d(0, 150%, 0);
   }

   to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
   }

}

@keyframes fadeIn {
   from {
      opacity: 0;
      -webkit-transform: translate3d(0, 150%, 0);
      -ms-transform: translate3d(0, 150%, 0);
      transform: translate3d(0, 150%, 0);
   }

   to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
   }

}

/* fade out */
@-webkit-keyframes fadeOut {
   from {
      opacity: 1;
   }

   to {
      opacity: 0;
      -webkit-transform: translate3d(0, -150%, 0);
      -ms-transform: translate3d(0, -150%, 0);
      transform: translate3d(0, -150%, 0);
   }

}

@keyframes fadeOut {
   from {
      opacity: 1;
   }

   to {
      opacity: 0;
      -webkit-transform: translate3d(0, -150%, 0);
      -ms-transform: translate3d(0, -150%, 0);
      transform: translate3d(0, -150%, 0);
   }

}

/* -------------------------------------------------------------------
 * responsive:
 * pageheader content
 * ------------------------------------------------------------------- */
@media only screen and (max-width:1400px) {
   .pageheader-content {
      padding: 0;
   }
   .featured::before, .featured::after {
      display: none;
   }

}

@media only screen and (max-width:1200px) {
   .featured {
      height: auto;
   }

   .featured__column--big .entry {
      height: 522px;
   }

   .featured__column--big .entry h1 {
      font-size: 3.2rem;
   }

   .featured__column--small .entry {
      height: 261px;
   }

}

@media only screen and (max-width:1000px) {
   .featured__column--big {
      width: 60%;
   }

   .featured__column--big .entry {
      height: 504px;
   }

   .featured__column--big .entry h1 {
      font-size: 3rem;
   }

   .featured__column--big .entry__content {
      padding: 0 10% 0 4.2rem;
   }

   .featured__column--small {
      width: 40%;
   }

   .featured__column--small .entry {
      height: 252px;
   }

}

@media only screen and (max-width:900px) {
   .pageheader-content {
      max-width: 780px;
   }

   .featured__column {
      float: none;
      width: 100%;
   }

   .featured__column--big .entry {
      height: 468px;
   }

   .featured__column--small:after {
      content: "";
      display: table;
      clear: both;
   }

   .featured__column--small .entry {
      height: 300px;
      float: left;
      width: 50%;
   }

}

@media only screen and (max-width:800px) {
   .featured__column .entry {
      height: 360px;
   }

   .featured__column .entry h1 {
      font-size: 2.8rem;
      margin-bottom: 3rem;
   }

   .featured__column .entry__content {
      bottom: 5.4rem;
      padding: 0 10% 0 4.2rem;
   }

   .featured__column--small .entry {
      float: none;
      width: 100%;
   }

   .featured__column--small .entry:last-child {
      display: none;
   }

   .featured__column--small .entry__category {
      font-size: 1.1rem;
      line-height: 2.4rem;
      margin-bottom: 2.4rem;
   }

   .featured__column--small .entry__profile-pic {
      display: block;
   }

   .featured__column--small .entry__meta {
      margin: 0 0 0 6rem;
      font-size: 1.6rem;
   }

}

@media only screen and (max-width:700px) {
   .pageheader-content {
      max-width: 540px;
   }

}

@media only screen and (max-width:600px) {
   .pageheader-content {
      max-width: 500px;
      width: auto;
      padding-left: 25px;
      padding-right: 25px;
   }

   .featured__column .entry {
      height: 330px;
   }

   .featured__column .entry h1 {
      font-size: 2.6rem;
      margin-bottom: 1.5rem;
   }

   .featured__column .entry__content {
      bottom: 4.2rem;
      padding: 0 3rem 0;
   }

   .featured__column .entry__category {
      font-size: 1rem;
      line-height: 2.1rem;
      margin-bottom: 1.5rem;
   }

   .featured__column .entry__profile-pic {
      display: none;
   }

   .featured__column .entry__meta {
      margin: 0rem;
      font-size: 1.5rem;
   }

}

@media only screen and (max-width:500px) {
   .featured__column .entry h1 {
      font-size: 2.4rem;
   }

   .featured .entry__content {
      bottom: 3.6rem;
   }

   .featured .entry__meta {
      font-size: 1.4rem;
   }

}

@media only screen and (max-width:400px) {
   .featured__column .entry {
      height: 288px;
   }

   .featured__column .entry h1 {
      font-size: 2rem;
   }

   .featured .entry__content {
      bottom: 3rem;
      padding: 0 2.4rem 0;
   }

}



/* ===================================================================
 * # s-content
 *
 * ------------------------------------------------------------------- */
.s-content {
   background-color: #f2f2f2;
   padding-top: 8.4rem;
   padding-bottom: 7.2rem;
}

.s-content--no-padding-bottom {
   padding-bottom: 0;
}

.s-content--narrow .row {
   max-width: 960px;
}

.s-content__header {
   padding-bottom: 3rem;
   text-align: center;
}

.s-content__header h1 {
   font-size: 4.8rem;
   line-height: 1.25;
   margin-top: 0;
   letter-spacing: -.15rem;
}

.s-content__header .lead {
   color: rgba(0, 0, 0, 0.5);
}

/* -------------------------------------------------------------------
 * responsive:
 * s-content
 * ------------------------------------------------------------------- */
@media only screen and (max-width:1200px) {
   .s-content--narrow .row {
      max-width: 900px;
   }

}

@media only screen and (max-width:1000px) {
   .s-content--narrow .row {
      max-width: 800px;
   }

   .s-content__header h1 {
      font-size: 4.5rem;
   }

}

@media only screen and (max-width:800px) {
   .s-content {
      padding-top: 6rem;
   }

   .s-content__header h1 {
      font-size: 4rem;
   }

}

@media only screen and (max-width:600px) {
   .s-content__header h1 {
      font-size: 3.6rem;
   }

}

/* -------------------------------------------------------------------
 * ## masonry layout
 * ------------------------------------------------------------------- */
.masonry-wrap {
   max-width: none;
   width: 1440px;
   overflow: hidden;
}

{
   margin-bottom: 3rem;
}

.masonry:after {
   content: "";
   display: table;
   clear: both;
}

.grid-sizer, .masonry__brick {
   width: 320px;
}

.masonry__brick {
   float: left;
   padding: 0 20px;
}

/* -------------------------------------------------------------------
 * responsive:
 * masonry layout
 * ------------------------------------------------------------------- */
@media only screen and (max-width:1480px) {
   .masonry-wrap {
      width: 1080px;
   }

}

@media only screen and (max-width:1140px) {
   .masonry-wrap {
      width: 1020px;
   }

   .grid-sizer, .masonry__brick {
      width: 340px;
   }

}

@media only screen and (max-width:1060px) {
   .masonry-wrap {
      width: 740px;
   }

   .grid-sizer, .masonry__brick {
      width: 370px;
   }

}

@media only screen and (max-width:800px) {
   .masonry-wrap {
      max-width: 700px;
      width: 90%;
   }

   .grid-sizer, .masonry__brick {
      width: 50%;
      padding: 0 15px;
   }

}

@media only screen and (max-width:700px) {
   .masonry-wrap {
      max-width: 400px;
   }

   .grid-sizer, .masonry__brick {
      width: 100%;
      clear: both;
   }

}

@media only screen and (max-width:600px) {
   .masonry-wrap {
      width: auto;
   }

   .grid-sizer, .masonry__brick {
      padding: 0 10px;
   }

}

@media only screen and (max-width:400px) {
   .masonry__brick {
      width: 100% !important;
      float: none !important;
      clear: both !important;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
   }

}


/* ===================================================================
 * # s-extra
 *
 * ------------------------------------------------------------------- */
.s-extra {
   background-color: #FFFFFF;
   padding-top: 7.8rem;
   padding-bottom: 9rem;
   font-family: "metropolis-regular", sans-serif;
   font-size: 1.5rem;
   line-height: 1.8;
}

.s-extra a {
   color: #000000;
}

.s-extra a:hover, .s-extra a:focus {
   color: #0054a5;
}

.s-extra h3, .s-extra h5 {
   margin-top: 0;
}

.s-extra h3 {
   font-size: 2.4rem;
   line-height: 1.375;
   margin-bottom: 3rem;
}

.s-extra h5 {
   font-family: "metropolis-extrabold", sans-serif;
   font-size: 1.6rem;
   line-height: 1.3125;
   margin-bottom: .6rem;
}

.s-extra .top [class*="col-"] {
   margin-bottom: 4.8rem;
}


/* -------------------------------------------------------------------
 * ## popular posts
 * ------------------------------------------------------------------- */
.s-extra .popular__post {
   position: relative;
   padding-left: 9.5rem;
   margin-bottom: 3.3rem;
   min-height: 5.4rem;
}

.s-extra .popular__thumb {
   position: absolute;
   top: .3rem;
   left: 0;
}

.s-extra .popular__thumb img {
   height: 6.9rem;
   width: 6.9rem;
}

.s-extra .popular__meta {
   font-size: 1.3rem;
   line-height: 1.615;
}


/* -------------------------------------------------------------------
 * ## about
 * ------------------------------------------------------------------- */
.s-extra .about {
   padding: 0 40px;
}

.s-extra .about p {
   margin-bottom: 1.5rem;
}

.s-extra .about__social {
   list-style: none;
   margin: 0;
   padding: 0;
   font-size: 2.4rem;
}

.s-extra .about__social li {
   display: inline-block;
   padding-left: 0;
   margin-right: 9px;
}


/* -------------------------------------------------------------------
 * ## tags
 * ------------------------------------------------------------------- */
.s-extra .tags-wrap {
   max-width: 900px;
}

.s-extra .tags {
   text-align: center;
}

.s-extra .tagcloud {
   padding-top: .6rem;
}

.s-extra .tagcloud a {
   display: inline-block;
   font-family: "metropolis-extrabold", sans-serif;
   font-size: 1.3rem;
   line-height: 3.6rem;
   text-transform: uppercase;
   letter-spacing: .2rem;
   padding: 0 2rem;
   margin: 0 1.2rem 1.5rem 0;
   border-radius: 3px;
   background: #e2e2e2;
   text-decoration: none;
   color: #333333;
   position: relative;
}

.s-extra .tagcloud a:hover, .s-extra .tagcloud a:focus {
   color: #FFFFFF;
   background: #151515;
}

/* -------------------------------------------------------------------
 * responsive:
 * s-extra
 * ------------------------------------------------------------------- */
@media only screen and (max-width:1200px) {
   .s-extra .top {
      max-width: 900px;
   }

   .s-extra .popular__posts article:nth-child(n+5) {
      display: none;
   }

}

@media only screen and (max-width:1000px) {
   .s-extra .top, .s-extra .tags-wrap {
      max-width: 800px;
   }

}

@media only screen and (max-width:800px) {
   .s-extra .top, .s-extra .tags-wrap {
      max-width: 550px;
   }

   .s-extra .about {
      padding: 0 15px;
   }

   .s-extra .tags {
      text-align: left;
   }

}

@media only screen and (max-width:600px) {
   .s-extra h3, .s-extra .about, .s-extra .tags {
      text-align: center;
   }

   .s-extra .popular__thumb {
      left: .6rem;
   }

   .s-extra .popular__thumb img {
      height: 6.6rem;
      width: 6.6rem;
   }

   .s-extra .about {
      padding: 0 10px;
   }

}

@media only screen and (max-width:400px) {
   .s-extra .popular__post {
      padding-left: 8.5rem;
   }

   .s-extra .popular__thumb {
      left: .6rem;
   }

   .s-extra .popular__thumb img {
      height: 6.3rem;
      width: 6.3rem;
   }

   .s-extra .about {
      padding: 0;
   }

}



/* ===================================================================
 * # footer
 *
 * ------------------------------------------------------------------- */
footer {
   padding-top: 10.2rem;
   padding-bottom: 6rem;
   font-size: 1.5rem;
   line-height: 2;
   color: rgba(255, 255, 255, 0.4);
}

footer a {
   color: #FFFFFF;
}

footer h4 {
   font-family: "metropolis-bold", sans-serif;
   font-size: 1.4rem;
   line-height: 1.286;
   text-transform: uppercase;
   letter-spacing: .25rem;
   padding-bottom: 4.2rem;
   color: #FFFFFF;
   margin-top: 0;
   margin-bottom: 1.8rem;
   position: relative;
}

footer h4::after {
   content: "";
   display: block;
   height: 1px;
   width: 120px;
   background-color: rgba(255, 255, 255, 0.1);
   position: absolute;
   left: 0;
   bottom: 0;
}

.s-footer__main {
   margin-bottom: 3.6rem;
}

.col-five p {
  margin-bottom: 3rem;
}

/* -------------------------------------------------------------------
 * ## footer link list
 * ------------------------------------------------------------------- */
.s-footer__linklist {
   list-style: none;
   margin-left: 0;
}

.s-footer__linklist a {
   color: rgba(255, 255, 255, 0.4);
}

.s-footer__linklist li {
   padding-left: 0;
}


/* -------------------------------------------------------------------
 * ## subscribe form
 * ------------------------------------------------------------------- */
.s-footer__subscribe .subscribe-form {
   margin: 0;
   padding: 0;
   position: relative;
}

.s-footer__subscribe #mc-form {
   width: 100%;
}

.s-footer__subscribe #mc-form input[type="email"] {
   width: 100%;
   height: 5.4rem;
   font-size: 1.5rem;
   padding: 1.2rem 100px 1.2rem 60px;
   background: url(../images/icons/icon-mail.svg) 20px center no-repeat, rgba(0, 0, 0, 0.1);
   background-size: 24px 16px, auto;
   margin-bottom: 1.8rem;
   color: rgba(255, 255, 255, 0.5);
   border-radius: 3px;
   border: none;
}

.s-footer__subscribe #mc-form input[type="email"]:hover,
.s-footer__subscribe #mc-form input[type="email"]:focus {
   color: #FFFFFF;
}

.s-footer__subscribe #mc-form input[type="email"]::-webkit-input-placeholder {
   color: rgba(255, 255, 255, 0.2);
}

.s-footer__subscribe #mc-form input[type="email"]:-moz-placeholder {
   color: rgba(255, 255, 255, 0.2);  /* Firefox 18- */
}

.s-footer__subscribe #mc-form input[type="email"]::-moz-placeholder {
   color: rgba(255, 255, 255, 0.2);  /* Firefox 19+ */
}

.s-footer__subscribe #mc-form input[type="email"]:-ms-input-placeholder {
   color: rgba(255, 255, 255, 0.2);
}

.s-footer__subscribe #mc-form input[type="email"].placeholder {
   color: rgba(255, 255, 255, 0.2) !important;
}

.s-footer__subscribe #mc-form input[type=submit] {
   position: absolute;
   top: 0;
   right: 0;
   color: #FFFFFF;
   background: #0054a5;
   border-color: #0054a5;
   padding: 0 15px;
   border-radius: 0 3px 3px 0;
}

.s-footer__subscribe #mc-form label {
   color: #FFFFFF;
   font-family: "metropolis-regular", sans-serif, sans-serif;
   font-size: 1.3rem;
}

.s-footer__subscribe #mc-form label i {
   padding-right: 6px;
}


/* -------------------------------------------------------------------
 * ## copyright
 * ------------------------------------------------------------------- */
.s-footer__bottom .s-footer__copyright span {
   display: inline-block;
}

.s-footer__bottom .s-footer__copyright span::after {
   content: "|";
   display: inline-block;
   padding: 0 1rem 0 1.2rem;
   color: rgba(255, 255, 255, 0.1);
}

.s-footer__bottom .s-footer__copyright span:last-child::after {
   display: none;
}


/* -------------------------------------------------------------------
 * ## go to top
 * ------------------------------------------------------------------- */
.go-top {
   position: fixed;
   bottom: 30px;
   right: 30px;
   z-index: 600;
   opacity: 0;
   visibility: hidden;
   -webkit-transition: all 0.8s ease-in-out;
   transition: all 0.8s ease-in-out;
   -webkit-transform: translate3d(0, 200%, 0);
   -ms-transform: translate3d(0, 200%, 0);
   transform: translate3d(0, 200%, 0);
}

.go-top a, .go-top a:visited {
   text-decoration: none;
   border: 0 none;
   display: block;
   height: 5.4rem;
   width: 5.4rem;
   line-height: 5.4rem;
   text-align: center;
   background-image: url(../images/icons/icon-arrow-up.svg);
   background-color: #000000;
   background-repeat: no-repeat;
   background-position: center center;
   background-size: 10px 16px;
   color: rgba(255, 255, 255, 0.5);
   text-align: center;
   text-transform: uppercase;
   border-radius: 50%;
   -webkit-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
}

.go-top a:hover, .go-top a:focus {
   color: #FFFFFF;
   background-color: #0054a5;
}

.go-top.link-is-visible {
   opacity: 1;
   visibility: visible;
   -webkit-transform: translate3d(0, 0, 0);
   -ms-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
}

/* -------------------------------------------------------------------
 * responsive:
 * footer components
 * ------------------------------------------------------------------- */
@media only screen and (max-width:1200px) {
   .s-footer__main > .row {
      max-width: 800px;
   }

   .s-footer__sitelinks, .s-footer__archives, .s-footer__social {
      margin-bottom: 3rem;
   }

   .s-footer__subscribe {
      clear: both;
   }

   .s-footer__bottom {
      text-align: center;
   }

}

@media only screen and (max-width:600px) {
   footer {
      padding-top: 7.8rem;
   }

   .s-footer__main .row {
      max-width: 480px;
   }

   .s-footer__main h4 {
      padding-bottom: 0;
      margin-bottom: 2.4rem;
   }

   .s-footer__main h4::after {
      display: none;
   }

   .s-footer__main .s-footer__linklist {
      width: 80%;
      margin: 0 auto;
   }

   .s-footer__main .s-footer__linklist li a {
      display: block;
      border-bottom: 1px solid rgba(255, 255, 255, 0.03);
      padding: .6rem 0;
   }

   .s-footer__main .s-footer__linklist li:first-child a {
      border-top: 1px solid rgba(255, 255, 255, 0.03);
   }

   .s-footer__sitelinks, .s-footer__archives, .s-footer__social, .s-footer__subscribe {
      text-align: center;
   }

   .s-footer__sitelinks, .s-footer__archives, .s-footer__social {
      margin-bottom: 4.2rem;
   }

   .s-footer__main .row [class*="col-"]:nth-child(2) {
      display: none;
   }

   .s-footer__bottom {
      padding-bottom: .6rem;
   }

   .s-footer__bottom .s-footer__copyright span {
      display: block;
   }

   .s-footer__bottom .s-footer__copyright span::after {
      display: none;
   }

}

/* ===================================================================
 * # blog styles
 *
 * ------------------------------------------------------------------- */
.s-content--narrow .s-content__header {
   padding: 0 8rem;
}

.s-content--narrow h3.remove-top-margin {
   margin-top: 0;
}

.s-content--narrow h3.quarter-top-margin {
   margin-top: 1.5rem;
}

.s-content--narrow h3.half-top-margin {
   margin-top: 3rem;
}

.s-content__header-title {
   font-size: 4.2rem;
   line-height: 1.214;
   margin-bottom: .9rem;
}

.s-content__header-meta {
   list-style: none;
   margin-left: 0;
   margin-bottom: 1.2rem;
   font-family: "metropolis-medium", sans-serif;
   font-size: 1.5rem;
   line-height: 1.8;
   position: relative;
}

.s-content__header-meta li {
   display: inline-block;
   padding-left: 0;
   margin: 0 1rem;
}

.s-content__header-meta span, .s-content__header-meta a {
   color: #000000;
}

.s-content__header-meta .cat a::after {
   content: ",";
}

.s-content__header-meta .cat a:last-child::after {
   content: none;
}


/* -------------------------------------------------------------------
 * ## content main
 * ------------------------------------------------------------------- */
.s-content__main {
   margin-top: 6rem;
   padding-left: 6rem;
   padding-right: 6rem;
}

.s-content__main img {
   max-width: 960px;
   margin: 3rem 0 3rem -6rem;
}


/* -------------------------------------------------------------------
 * ## content media
 * ------------------------------------------------------------------- */
.s-content__media {
   padding: 0;
   margin-top: 4.8rem;
}

.s-content__media img {
   vertical-align: bottom;
}

.s-content__media, .s-content__post-thumb {
   position: relative;
}


/* -------------------------------------------------------------------
 * ## post tags
 * ------------------------------------------------------------------- */
.s-content__tags {
   margin-top: 6rem;
   font-family: "metropolis-regular", sans-serif;
}

.s-content__tags span {
   font-family: "metropolis-bold", sans-serif;
   color: #151515;
   position: relative;
   padding-right: 55px;
   margin-right: 1.5rem;
   margin-top: 1.2rem;
}

.s-content__tags span::after {
   display: inline-block;
   content: "";
   height: 1px;
   width: 40px;
   background-color: rgba(0, 0, 0, 0.2);
   position: absolute;
   top: 50%;
   right: 0;
}

.s-content__tags .s-content__tag-list {
   display: inline-block;
   padding-right: 0;
   margin-right: 0;
}

.s-content__tags .s-content__tag-list::after {
   display: none;
}

.s-content__tags .s-content__tag-list a {
   display: inline-block;
   font-family: "metropolis-regular", sans-serif;
   font-size: 1.3rem;
   line-height: inherit;
   color: #7f7f7f;
   border: 1px solid rgba(0, 0, 0, 0.3);
   border-radius: 3px;
   margin-right: 6px;
   margin-bottom: 6px;
   padding: 0 10px;
}

.s-content__tags .s-content__tag-list a:hover,
.s-content__tags .s-content__tag-list a:focus {
   background-color: #000000;
   border-color: #000000;
   color: #ffffff;
}

.s-content__tags .s-content__tag-list a:last-child {
   margin-right: 0;
}


/* -------------------------------------------------------------------
 * ## author profile
 * ------------------------------------------------------------------- */
.s-content__author {
   margin-top: 10.2rem;
   padding-left: 9rem;
   position: relative;
}

.s-content__author img {
   margin: 0 !important;
   width: 6.6rem;
   height: 6.6rem;
   border-radius: 50%;
   position: absolute;
   left: 0;
   top: 0;
}

.s-content__author p {
   margin-bottom: 1.2rem;
}

.s-content__author-name {
   margin-top: 0;
}

.s-content__author-name a {
   color: #151515;
}

.s-content__author-social {
   list-style: none;
   margin-left: 0;
   font-family: "metropolis-bold", sans-serif;
   font-size: 12px;
   text-transform: uppercase;
   letter-spacing: 1px;
}

.s-content__author-social li {
   display: inline-block;
   margin-right: 1rem;
   padding-left: 0;
}

.s-content__author-social a {
   color: #151515;
}


/* -------------------------------------------------------------------
 * ## blog single page navigation
 * ------------------------------------------------------------------- */
.s-content__pagenav {
   margin-top: 10.2rem;
   padding-top: 1.8rem;
   border-top: 1px solid rgba(0, 0, 0, 0.1);
   position: relative;
}

.s-content__nav:after {
   content: "";
   display: table;
   clear: both;
}

.s-content__nav span {
   display: block;
   font-family: "metropolis-medium", sans-serif;
   font-size: 12px;
   text-transform: uppercase;
   letter-spacing: 2.5px;
   color: #7f7f7f;
   margin-bottom: 1.2rem;
   -webkit-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
}

.s-content__nav a {
   font-family: "librebaskerville-bold", serif;
   font-size: 2.2rem;
   line-height: 1.5;
   color: #19191b;
}

.s-content__nav a:hover span, .s-content__nav a:focus span {
   color: #19191b;
}

.s-content__prev, .s-content__next {
   width: 50%;
   float: left;
   padding-right: 2rem;
}


/* -------------------------------------------------------------------
 * ## format gallery
 * ------------------------------------------------------------------- */
.format-gallery .entry__thumb {
   overflow: hidden;
}

.format-gallery .slider {
   overflow: hidden;
}

.format-gallery .slick-dots {
   text-align: left;
   margin: 0;
   padding: 0 2rem 0 2.4rem;
   position: absolute;
   top: auto;
   bottom: 2.4rem;
   left: 0;
}

.format-gallery .slider__slides {
   cursor: pointer;
   overflow: hidden;
   opacity: 0;
   visibility: hidden;
}

.format-gallery .slider__slides.slick-initialized {
   opacity: 1;
   visibility: visible;
}


/* -------------------------------------------------------------------
 * ## format video
 * ------------------------------------------------------------------- */
.format-video .video-container {
   margin-bottom: 0;
}


/* -------------------------------------------------------------------
 * ## format audio
 * ------------------------------------------------------------------- */
.format-audio .audio-wrap {
   position: absolute !important;
   bottom: 0;
   left: 0;
   width: 100%;
}

/* -------------------------------------------------------------------
 * responsive:
 * blog styles
 * ------------------------------------------------------------------- */
@media only screen and (max-width:1200px) {
   .s-content--narrow .s-content__header {
      padding: 0 6rem;
   }

   .s-content__main {
      padding-left: 5rem;
      padding-right: 5rem;
   }

   .s-content__main img {
      max-width: 900px;
      margin: 3rem 0 3rem -5rem;
   }

}

@media only screen and (max-width:1000px) {
   .s-content--narrow .s-content__header {
      padding: 0 6rem;
   }

   .s-content__header-title {
      font-size: 3.8rem;
   }

   .s-content__main {
      padding-left: 4rem;
      padding-right: 4rem;
   }

   .s-content__main img {
      max-width: 800px;
      margin: 3rem 0 3rem -4rem;
   }

}

@media only screen and (max-width:900px) {
   .s-content__main, .s-content__media {
      padding-left: 15px;
      padding-right: 15px;
   }

   .s-content--narrow .s-content__header {
      padding: 0 5rem;
   }

   .s-content__main img {
      max-width: 100%;
      margin: 3rem 0 3rem;
   }

}

@media only screen and (max-width:800px) {
   .s-content__header-title {
      font-size: 3.6rem;
   }

   .s-content__prev, .s-content__next {
      width: 100%;
      float: none;
      padding: 0;
      text-align: left;
      margin-bottom: 1.8rem;
   }

   .s-content__author {
      text-align: center;
      padding-left: 0;
   }

   .s-content__author img {
      position: static;
   }

   .s-content__author-name {
      margin-top: 1.2rem;
   }

}

@media only screen and (max-width:600px) {
   .s-content--narrow .s-content__header, .s-content__main, .s-content__media {
      padding-left: 10px;
      padding-right: 10px;
   }

   .s-content__prev, .s-content__next {
      text-align: center;
   }

}

@media only screen and (max-width:500px) {
   .s-content__header-title {
      font-size: 3.4rem;
   }

}

@media only screen and (max-width:400px) {
   .s-content--narrow .s-content__header, .s-content__main, .s-content__media {
      padding-left: 0;
      padding-right: 0;
   }

   .s-content--narrow .s-content__header {
      margin-top: 0;
   }

   .s-content__header-title {
      font-size: 3.3rem;
   }

}


/* -------------------------------------------------------------------
 * ## comments
 * ------------------------------------------------------------------- */
.comments-wrap {
   background-color: #e5e5e5;
   padding: 8.4rem 0 9rem;
   margin-top: 10.2rem;
}

/* comments
 * -------------------------------------------------- */
#comments {
   padding-top: 1.2rem;
   padding-bottom: 1.2rem;
   max-width: 880px;
   color: #4c4c4c;
}

#comments h3 {
   margin-top: 0;
   margin-bottom: 1.5rem;
}

#comments ol, #comments ul {
   list-style: none;
}

.commentlist {
   margin: 6rem 0 5.4rem;
   padding: 0;
}

.commentlist > .comment {
   position: relative;
   list-style: none;
   margin: 0;
   padding: 1.8rem 0;
   padding-left: 14%;
}

.comment__avatar {
   position: absolute;
   left: 0;
   display: block;
   height: 6rem;
   width: 6rem;
}

.comment__avatar img {
   margin-top: 0.6rem;
   height: 6rem;
   width: 6rem;
   border-radius: 100%;
}

.comment__info cite {
   font-family: "metropolis-bold", sans-serif;
   font-size: 1.9rem;
   line-height: 1.263;
   font-style: normal;
   color: #151515;
}

.comment__meta {
   font-family: "metropolis-regular", sans-serif;
   font-size: 1.45rem;
   line-height: 3rem;
   font-style: normal;
   display: block;
   color: rgba(0, 0, 0, 0.7);
}

.comment__meta time {
   margin-right: 12px;
}

.comment__meta .reply {
   font-family: "metropolis-semibold", sans-serif;
}

.comment__text {
   clear: both;
   margin: 1.8rem 0 0 0;
   padding: 0;
}

.comment ul.children {
   margin: 0;
   padding: 1.8rem 0 0 0;
}

.comment ul.children li {
   padding-left: 5%;
   padding-top: 1.8rem;
}

/* comments form
 * ---------------------------- */
#comments .respond {
   padding-top: 4.2rem;
}

#comments form {
   padding-top: 3rem;
}

#comments form .form-field {
   margin-bottom: 3.9rem;
}

/* -------------------------------------------------------------------
 * responsive:
 * comments
 * ------------------------------------------------------------------- */
@media only screen and (max-width:1200px) {
   #comments {
      max-width: 830px;
   }

}

@media only screen and (max-width:1000px) {
   #comments {
      max-width: 750px;
   }

}

@media only screen and (max-width:800px) {
   #comments form button {
      width: 100%;
   }

   .comment__avatar {
      height: 5.1rem;
      width: 5.1rem;
   }

   .comment__avatar img {
      margin-top: 0;
      height: 5.1rem;
      width: 5.1rem;
   }

}

@media only screen and (max-width:600px) {
   #comments h3 {
      text-align: center;
   }

   .commentlist > .comment {
      padding-left: 0;
   }

   .commentlist .comment__avatar {
      display: none;
   }

}



/* ===================================================================
 * # page styles
 *
 * ------------------------------------------------------------------- */

/* -------------------------------------------------------------------
 * ## contact page
 * ------------------------------------------------------------------- */

/* map
 * ----------------------------------------------- */
#map-wrap {
   background: #FFFFFF;
   z-index: 500;
   height: 420px;
}

#map-wrap .gm-style img {
   max-width: none;
}

#map-wrap .gm-style label {
   width: auto;
   display: inline;
}

#map-wrap #map-container {
   height: 100%;
   width: 100%;
   background: #DDDDDD;
   position: relative;
}

#map-wrap #map-zoom-in, #map-wrap #map-zoom-out {
   height: 3.2rem;
   width: 3.2rem;
   cursor: pointer;
   margin-right: 4rem;
   color: white;
   background-color: #000000;
   display: none;
}

#map-wrap #map-zoom-in {
   margin-bottom: 2px;
   margin-top: 30px;
}

#map-wrap #map-zoom-in::before, #map-wrap #map-zoom-out::before {
   display: block;
   color: white;
   text-align: center;
   font-family: 'FontAwesome';
   font-size: 14px;
   content: "\f067";
   height: 32px;
   width: 32px;
   line-height: 32px;
   -webkit-transition: color 0.3s ease-in-out;
   transition: color 0.3s ease-in-out;
}

#map-wrap #map-zoom-in::before {
   content: "\f067";
}

#map-wrap #map-zoom-out::before {
   content: "\f068";
}

#map-wrap #map-zoom-in:hover::before, #map-wrap #map-zoom-out:hover::before {
   background-color: #0054a5;
}

/* contact form
 * ----------------------------------------------- */
#cForm {
   margin-top: 3.6rem;
}

/*# sourceMappingURL=main.css.map */
